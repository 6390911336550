import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';

import App from './OLMapUI';
import * as serviceWorker from './serviceWorker';
import styles from './index.scss'; // eslint-disable-line

Sentry.init({dsn: "https://bf88dcf83b974014960c19be635cae9c@sentry.fvh.io/5"});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
